import * as React from "react";

import { Button as ActionButton } from "@components/Button";
import { Disclosure, Transition } from "@headlessui/react";
import { Indecator as InternalIndicator } from "./Indecator";
import { useLocalStorage } from 'react-use';
import * as styles from "./index.module.css";
import { paramCase } from "change-case";

type CollapsibleProps = {
  title: string;
  showTitle?: boolean;
  show: boolean;
  expander?: React.ElementType;
  openLabel?: string;
  closeLabel?: string;
  listElement?: React.ElementType;
  children: React.ReactNode;
  _key?: string;
  sessionKey?: string;
};

export const Indicator = InternalIndicator;

export const Button = (props: any) => {
  return (
    <Disclosure.Button {...props} as={ActionButton} variant="action" size="small">
      {({ open }) => {
        const children = typeof props.children == "function" ? props.children(open) : props.children;

        return (
          <>
            <InternalIndicator open={open} />

            {children}
          </>
        );
      }}
    </Disclosure.Button>
  );
};

export const Collapsible: React.FC<CollapsibleProps> = ({
  title,
  showTitle = true,
  show,
  expander,
  openLabel = "Open",
  closeLabel = "Close",
  listElement,
  children,
  _key,
}) => {
  const updatedSessionKey = _key || (paramCase(title))
  const [isOpen, setOpen, remove] = useLocalStorage(updatedSessionKey, show && "true");
  const Button = expander || "button";

  return (
    <Disclosure defaultOpen={isOpen ? true : false}>
      {({ open }) => {
        React.useEffect(() => {
          open ? setOpen("true") : remove();
        }, [open])
        return (
          <div className={styles.collapseItem + " w-full"}>
            {showTitle && <div className={styles.collapseTitle}>{title}</div>}

            <div className={`collapse-expander ${styles.collapseExpander}`}>
              <div data-collapse-key={updatedSessionKey}>
                <Button>
                  <InternalIndicator open={open} />

                  <span>{open ? closeLabel : openLabel}</span>
                </Button>
              </div>
              <div className={`max-h-fit`}>
                <Transition
                  show={open}
                  enter="transition duration-[.4s] ease-out"
                  enterFrom="transform w-full max-h-[0px] opacity-0"
                  enterTo="transform max-h-full opacity-100 "
                  leave="transition duration-[.2s]  ease-out"
                  leaveFrom="transform max-h-full opacity-100 "
                  leaveTo="transform opacity-0 "
                >
                  <Disclosure.Panel
                    as={listElement || "div"}
                    className={styles.panel}
                  >
                    {children}
                  </Disclosure.Panel>
                </Transition>
              </div>
            </div>
          </div>
        )
      }}
    </Disclosure>
  );
};
