import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import { filter, fromEvent, map, merge } from 'rxjs';

type TriggerName = "effectivePractice" | "assessment"

export const effectivePracticesToggleAtom = atom<Map<string, boolean>>(new Map());
export const assessmentToggleAtom = atom<Map<string, boolean>>(new Map());

const addAtom = atom(null, (get, set, {key, value, trigger}: {key: string, trigger: TriggerName, value?: boolean}) => {
  const triggerAtom = trigger === "effectivePractice" ? effectivePracticesToggleAtom : assessmentToggleAtom;
  const map = get(triggerAtom);
  if (value === null || value === undefined) {
    map.set(key, localStorage.getItem(key) === "true" ?? false);
  } else if (value === map.get(key)) {
    return; // no change
  } else {
    localStorage.setItem(key, value.toString());
    map.set(key, value);
  }
  set(triggerAtom, new Map(map));
});

const updateAll = atom(null, (get, set, {value, trigger}: {value: boolean, trigger: TriggerName}) => {
  const triggerAtom = trigger === "effectivePractice" ? effectivePracticesToggleAtom : assessmentToggleAtom;
  const map = get(triggerAtom);
  map.forEach((_, mapK) => {
    localStorage.setItem(mapK, value.toString());
    map.set(mapK, value);
  })
  set(triggerAtom, new Map(map));
});

const useToggle = (globalTrigger: TriggerName) => {
  const value = useAtomValue(globalTrigger === "effectivePractice" ? effectivePracticesToggleAtom : assessmentToggleAtom);
  const update = useSetAtom(updateAll);
  return [[...value.values()].every((v) => v), (updateValue: boolean) => update({value: updateValue, trigger: globalTrigger})] as [boolean, (val: boolean) => void];
}

export const useEffectivePracticesToggle = () => {
  return useToggle("effectivePractice");
}

export const useAssessmentsToggle = () => {
  return useToggle("assessment");
}

export const useStateToggle = ({key, globalTrigger}: {key:string, globalTrigger: TriggerName}) => {
  const setState = useSetAtom(addAtom);
  return (value?: boolean) => setState({ key, value, trigger: globalTrigger });
}

export const useCollapse = (key: string, globalTrigger: TriggerName) => {
  const currState = useAtomValue(React.useMemo(() => {
    return atom((get) => {
      const value = get(globalTrigger === "effectivePractice" ? effectivePracticesToggleAtom : assessmentToggleAtom);
      return value.get(key);
    });
  }, []));
  const updateState = useStateToggle({key, globalTrigger});

  React.useEffect(() => {
    updateState(); // populate cache
  }, []);

  React.useEffect(() => {
    const el = document.getElementById(key);
    if (!el) return;
    currState && !el.classList.contains("open") && HSCollapse.show(el);
    !currState && el.classList.contains("open") && HSCollapse.hide(el);
  }, [currState]);

  React.useEffect(() => {
    const showObserver = fromEvent(window, "open.hs.collapse").pipe(
      filter((e: any) => e.target.id === key),
      map((e) => ({el: e.target, value: true}))
    );

    const hideObserver = fromEvent(window, "hide.hs.collapse").pipe(
      filter((e: any) => e.target.id === key),
      map((e) => ({el: e.target, value: false}))
    )

    const toggleObserver = merge(showObserver, hideObserver).subscribe(({value}) => {
      updateState(value)
    });
    return () => {
      toggleObserver.unsubscribe();
    }
  })
}
