import * as React from "react";
import { RenderMultiple } from "@components/RenderMultiple";
import { Practices } from "./Practices";
import { paramCase } from "change-case";
import { Heading } from "@components/Heading";

const SideBar = () => (
  <div className="absolute mt-8 border-l h-[calc(100%-2.5rem)] -ml-4">
    <div className="h-full w-4 relative">
      <div className="bg-secondary w-[9px] h-[9px] rounded-full absolute [contents:''] ml-[-5px]" />
      <div className="bg-secondary w-[9px] h-[9px] rounded-full absolute [contents:''] ml-[-5px] bottom-0" />
    </div>
  </div>
);

export const EffectivePracticeGroup: React.FC<any> = (props) => {
  const headerRef = React.useRef<HTMLHeadingElement>(null);
  return (
    <li
      data-offset={(headerRef.current?.getBoundingClientRect().height ?? 0) + 70}
      style={{ "--header-offset": `${(headerRef.current?.getBoundingClientRect().height ?? 0) + 70}px` } as any}
      className="list-decimal scroll-mt-[70px] lg:scroll-mt-[74px] group/ep3-question"
      id={paramCase(props.title)}
      data-targetable
    >
      <Heading
        ref={headerRef}
        level={3}
        className="!font-semibold pl-2 lg:text-[28px] bg-white sticky  top-[70px] lg:top-[74px] mt-0 z-50 py-4"
      >
        {props.title}
      </Heading>
      <div className="relative after:ml-[-5px] after:mt-[-4px] h-fit">
        <SideBar />
        <ol className="ep3doc_effective_practices_level_2 ml-8 [&_li::marker]:leading-8 [&_li::before]:leading-8">
          <RenderMultiple
            of={Practices as any}
            fromPropsList={props.actionablePractices.map((a: any) => ({
              title: a.title,
              blocks: a._rawImplementationStrategies,
              offset: (headerRef.current?.getBoundingClientRect().height ?? 0) + 70,
            }))}
          />
        </ol>
      </div>
    </li>
  );
};
