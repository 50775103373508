import { Container } from "@components/Layout/Container";
import { Layout } from "@components/Layout";
import * as React from "react";
import { GuideHeader } from "@components/Header/Guide";
import { CycleGraphic } from "@components/EP3Document/CycleGraphic";
import { graphql } from "gatsby";
import { pageAtom, heroAtom, definitionsAtom, contributorsAtom } from "@/store";
import { EmailListForm } from "@components/EmailListForm";
import { Helmet } from "react-helmet";
import { useHydrateAtoms } from "jotai/utils";
import { EffectivePractices } from "@components/EP3Document/EffectivePractices";
import { ProgrammaticAssessments } from "@components/EP3Document/ProgrammaticAssessments";
import { DynamicComponent } from "@components/DynamicComponent";
import { Heading } from "@components/Heading";
import { Glossary } from "@components/EP3Document/Glossary";

import "@/styles/ep3Doc_page.css";
import { paramCase } from "change-case";
import { ModalTriggerButton, Modal, ModalCloseButton, InlineNavigationTrigger } from "../components/Dialog";
import { useSetAtom } from "jotai";
import { Disclosure, Transition } from "@headlessui/react";
import { BsChevronRight } from "react-icons/bs";
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PracticeGroup = ({ id, data, title, children, buttonLabel }: any) => {

  const groupNavRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const handleScroll = () => {
      const links = groupNavRef.current?.querySelectorAll("a") || ([] as any);

      const stickyNav = document.querySelector("div.sticky-nav");
      const stickyNavBoundary = stickyNav?.getBoundingClientRect();
      const selected = Array.from(links).reverse().find((link) => {
        const ele = document.querySelector("#" + (link as HTMLAnchorElement).href.split("#")[1]);

        if (ele) {
          const elementLocation = ele?.getBoundingClientRect();
          return elementLocation.y - (stickyNavBoundary?.height ?? 0) < 15;
        }
      });

      for (let linkEl of links) {
        const ele = document.querySelector("#" + linkEl.href.split("#")[1] + ">h3") || document.querySelector("#" + linkEl.href.split("#")[1] + ">div>div>button");;
        if (linkEl === selected) {
          linkEl.dataset.selected = "true";
          ele?.classList.add("shadow-lg")
        } else {
          delete linkEl.dataset.selected;
          ele?.classList.remove("shadow-lg")
        };
      }
    }
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, [groupNavRef.current])

  return (
    <div className="relative z-0">
      <div id={id} data-targetable className="group scroll-mt-4 lg:scroll-mt-20">
        <div className="max-w-[var(--max-w)] sticky top-0 z-50 bg-white flex justify-between items-start leading-tight lg:border-b lg:border-b-[var(--grey)] py-4 px-0 m-[2rem_auto_2rem_0] lg:p-0 border-b-0 gap-4">
          <div className="section-title lg:text-4xl lg:static font-bold  truncate overflow-hidden ">
            <span className="section-title__text text-base lg:text-2xl">{title}</span>
          </div>
          <div className="section-buttons lg:hidden">
            <ModalTriggerButton className="trigger overview-toggle border border-blue-400 inline-block px-2 text-sm whitespace-nowrap leading-tight" target={`dialog-${paramCase(title)}`}><FontAwesomeIcon icon={faLocationCrosshairs} /> {buttonLabel}</ModalTriggerButton>
          </div>
        </div>
        <div className="practices__body main-content grid grid-cols-1 lg:grid-cols-[1fr_2fr] gap-[var(--gap)] z-0 relative">
          <aside className="mb-8 bt-4 cyclebox practice-nav hidden lg:block">
            <div className="sidebar">
              <h3 className="sidebar-title bg-opacity-100">{title}</h3>
              <div className="scroll-shadows" ref={groupNavRef}>
                <ol className="sidebar-nav" >
                  {data?.map((item: any) => (
                    <li key={"sbn-" + paramCase(item.title)}><a href={item.url} className="sidebar-nav__link block data-[selected]:text-secondary data-[selected]:underline" data-link="">{item.title}</a></li>
                  ))}
                </ol>
              </div>
            </div>
          </aside>
          <Modal id={`dialog-${paramCase(title)}`}>
            <div className="w-fit bg-white">
              <div className="flex space-between w-full dialog-head">
                <h3 className="dialog-title text-white">{title}</h3>
                <ModalCloseButton target={`dialog-${paramCase(title)}`} />
              </div>
              <div className="dialog-body">
                <ol>
                  {data?.map((item: any) => (
                    <li key={"pg-" + paramCase(item.title)}><InlineNavigationTrigger as="a" href={item.url} className="sidebar-nav__link block" data-link="" target={`dialog-${paramCase(title)}`}>{item.title}</InlineNavigationTrigger></li>
                  ))}
                </ol>
              </div>
            </div>
          </Modal>
          <div className="article__body-content">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
};


const useNavigationScroll = () => {

  const stickyNavRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const handleScroll = () => {
      const stickyNavBoundary = stickyNavRef.current?.getBoundingClientRect();
      const selected = ["description", "benefits", "practices", "assessments", "resources", "evidence"].reverse().find((id) => {
        const ele = document.getElementById(id);
        if (ele) {
          const elementLocation = ele?.getBoundingClientRect();
          return elementLocation.y - (stickyNavBoundary?.height ?? 0) < 15;
        }
      });
      const links = stickyNavRef.current?.querySelectorAll("a") || ([] as any);
      for (let linkEl of links) {
        if (linkEl.getAttribute("href") === `#${selected}`) {
          linkEl.dataset.selected = "true";
        } else {
          delete linkEl.dataset.selected;
        };
      }
    }
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, [stickyNavRef.current])
  return stickyNavRef;
}


const Page: React.FC<any> = ({ data, pageContext }: any) => {
  const stickyNavRef = useNavigationScroll()
  const definitions = React.useMemo(() => {
    return pageContext.definitions.reduce((acc: Record<string, { term: string }[]>, curr: { term: string }) => {
      const letter = curr.term[0].toUpperCase();
      // eslint-disable-next-line functional/immutable-data
      acc[letter] ||= [];
      acc[letter].push(curr);
      acc[letter].sort((a, b) => a.term.localeCompare(b.term));
      return acc;
    }, {} as Record<string, { term: string }[]>);
  }, [pageContext.definitions])
  const setPage = useSetAtom(pageAtom, data);
  const setHero = useSetAtom(heroAtom, data.sanityEp3Document?.hero || data.hero);
  const setContributors = useSetAtom(contributorsAtom, data.allSanityContributorsAndReviewers?.edges?.[0]?.node?._rawBody);
  const setDefinitions = useSetAtom(definitionsAtom, definitions)


  setPage(data)
  setContributors(data.allSanityContributorsAndReviewers?.edges?.[0]?.node?._rawBody);
  setHero(data.sanityEp3Document?.hero || data.hero);
  setDefinitions(definitions);

  useHydrateAtoms([
    [pageAtom, data],
    [heroAtom, data.sanityEp3Document?.hero || data.hero],
    [definitionsAtom, definitions],
    [contributorsAtom, data.allSanityContributorsAndReviewers?.edges?.[0]?.node?._rawBody]
  ]);

  React.useEffect(() => {
    const triggerHashChange = (e) => {
      window.dispatchEvent(new HashChangeEvent("hashchange"))
    }
    const listener = () => {
      setTimeout(() => {
        const item = document.querySelector(window.location.hash)
        item?.scrollIntoView();
      }, 1000)
    }

    const internalReferenceLinks = document.querySelectorAll("a[href^='#']");
    internalReferenceLinks.forEach((link) => {
      link.addEventListener("click", triggerHashChange);
    });
    window.addEventListener("hashchange", listener)
    return () => {
      internalReferenceLinks.forEach((link) => {
        link.removeEventListener("click", triggerHashChange);
      });
      window.removeEventListener("hashchange", listener)
    }
  })

  React.useEffect(() => {
    const hash = window.location.hash;
    if (!hash) return;
    setTimeout(() => {
      window.dispatchEvent(new HashChangeEvent("hashchange"))
    }, 1000);
  }, []);


  return data?.sanityEp3Document ? (<Layout>
    <Helmet>
      <title> {data.sanityEp3Document?.seo.title || data.sanityEp3Document?.heading} | EP3 Guide</title>
    </Helmet>
    <Container>
      <article className="container-body gap-8 relative">
        <header>
          <GuideHeader />
        </header>
        <div className="sticky-nav-bar lg:hidden">
          <ModalTriggerButton className="sticky-hamburger hamburger border py-2 px-3 bg-white" target={"dialog-sticky-nav"}>
            ☰ In this guide
          </ModalTriggerButton>
        </div>
        <Modal id="dialog-sticky-nav">
          <div className=" m-auto w-fit bg-white">
            <div className="flex space-between w-full dialog-head">
              <h3 className="dialog-title">In this guide…</h3>
              <ModalCloseButton target="dialog-sticky-nav" />
            </div>
            <div className="dialog-body">
              <ol>
                <li><InlineNavigationTrigger as="a" target="dialog-sticky-nav" href="#description">Description</InlineNavigationTrigger></li>
                <li><InlineNavigationTrigger as="a" target="dialog-sticky-nav" href="#benefits">Benefits</InlineNavigationTrigger></li>
                <li><InlineNavigationTrigger as="a" target="dialog-sticky-nav" href="#practices">Effective Practices</InlineNavigationTrigger></li>
                {data.sanityEp3Document?.programmaticAssessments?.length > 0 && <li><InlineNavigationTrigger as="a" target="dialog-sticky-nav" href="#assessments">Assessments</InlineNavigationTrigger></li>}
                {data.sanityEp3Document?._rawResources && (<li><InlineNavigationTrigger as="a" target="dialog-sticky-nav" href="#resources">Resources</InlineNavigationTrigger></li>)}
                {data.sanityEp3Document?._rawEvidence && (<li><InlineNavigationTrigger as="a" target="dialog-sticky-nav" href="#evidence">Evidence</InlineNavigationTrigger></li>)}
              </ol>
            </div>
          </div>
        </Modal>
        <div id="sticky-nav"
          ref={stickyNavRef}
          className="sticky-nav hidden lg:block border-t-gray-400 border-t border-b-gray-400 border-b w-full scroll-pb-64 snap-y">
          <ul className="lg:flex lg:gap-8 data-[selected]:[&_a]:border-b-2 [&_a]:border-secondary">
            <li><a href="#description">Description</a></li>
            <li><a href="#benefits">Benefits</a></li>
            <li><a href="#practices">Effective Practices</a></li>
            {data.sanityEp3Document?.programmaticAssessments?.length > 0 && <li><a href="#assessments">Assessments</a></li>}
            {data.sanityEp3Document?._rawResources && (<li><a href="#resources">Resources</a></li>)}
            {data.sanityEp3Document?._rawEvidence && (<li><a href="#evidence">Evidence</a></li>)}
          </ul>
        </div>
        <div className="article__intro scroll-mt-[400px]">
          <div className="">
            <div id="description" data-targetable className="scroll-mt-4 lg:scroll-mt-20">
              <DynamicComponent blocks={data.sanityEp3Document?._rawDescription} />
            </div>
            <div id="benefits" data-targetable className="scroll-mt-4 lg:scroll-mt-20">
              <Heading level={2}>
                Benefits
              </Heading>

              <DynamicComponent blocks={data.sanityEp3Document?._rawBenefits} />
            </div>
          </div>
          <div className="article__intro-aside">
            <CycleGraphic />
            <div className="bg-gray-300 p-8 mb-8">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="font-bold text-2xl w-full flex justify-between items-center">EP3 Glossary
                      <BsChevronRight className={open ? 'rotate-90 transform' : ''} />
                    </Disclosure.Button>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0 max-h-0"
                      enterTo="transform scale-100 opacity-100 max-h-fit"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100 max-h-fit"
                      leaveTo="transform scale-95 opacity-0 max-h-0"
                    >
                      <Disclosure.Panel>
                        <Glossary />
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </div>
        {data.sanityEp3Document?.effectivePractices.length > 0 && (
          <div className="relative z-50">
            <PracticeGroup id="practices" title="Effective Practices" buttonLabel="Practices" data={data.sanityEp3Document?.effectivePractices?.map((action: any, idx: number) => ({
              title: action.title,
              url: "#" + paramCase(action.title),
            }))}>
              <EffectivePractices practices={data.sanityEp3Document?.effectivePractices} />
            </PracticeGroup>
          </div>
        )}
        {data.sanityEp3Document?.programmaticAssessments?.length > 0 && (
          <div className="relative z-40">
            <PracticeGroup id="assessments" title="Programmatic Assessments" buttonLabel="Assessments" data={data.sanityEp3Document?.programmaticAssessments?.map((action: any, idx: number) => ({
              title: action.title,
              url: "#" + paramCase(action.title),
            }))}>
              <ProgrammaticAssessments />
            </PracticeGroup>
          </div>
        )}
        {data.sanityEp3Document?._rawResources && (
          <div id="resources" data-targetable className="group scroll-mt-4 lg:scroll-mt-20 relative z-30">
            <div className="section-title__wrap">
              <label className="section-title"><span className="section-title__text text-base lg:text-2xl">Resources</span></label>
              <div className="section-buttons"></div>
            </div>
            <div className="practices__body main-content">
              <aside></aside>
              <div className="article__body-content [&_ol>li]:list-decimal  [&_ul>li]:list-disc">
                <DynamicComponent blocks={data.sanityEp3Document?._rawResources} />
              </div>
            </div>
          </div>
        )}        {data.sanityEp3Document?._rawEvidence && (
          <div id="evidence" data-targetable className="group scroll-mt-4 lg:scroll-mt-20 relative z-20">
            <div className="section-title__wrap">
              <label className="section-title"><span className="section-title__text text-base lg:text-2xl">Evidence</span></label>
              <div className="section-buttons"></div>
            </div>
            <div className="practices__body main-content">
              <aside></aside>
              <div className="article__body-content [&_ol>li]:list-decimal  [&_ul>li]:list-disc">
                <DynamicComponent blocks={data.sanityEp3Document?._rawEvidence} />
              </div>
            </div>
          </div>
        )}
        <div className="grid items-center justify-center w-full md:px-5 py-5 mt-12 z-10">
          <EmailListForm />
        </div>
      </article>
    </Container>
  </Layout>
  ) : <>Something went wrong.</>;
};

export default Page;

export const pageQuery = graphql`
    query ($slug: String) {
      hero: file(relativePath: {eq: "hero-high-school-physics-teacher-prep.jpg" }) {
      ...heroImageFragment
    }

    sanityEp3Document(slug: {current: {eq: $slug } }) {
      hero {
        asset: _rawAsset(resolveReferences: {maxDepth: 10 })
        crop {
          bottom
          left
          right
          top
        }
        hotspot {
          height
          width
          x
          y
        }
      }
      heading
      preamble
      slug {
        current
      }
      seo {
        title
          description
      }
      category
      CitationMetaData {
        author
        authors
        doi
        identifier
        publicationPlace
        publicationDate(formatString: "YYYY-MM-DD", locale: "en-US")
        lastRevised(formatString: "YYYY-MM-DD", locale: "en-US")
        publisher
        version
        title
        url
      }
      _rawDescription(resolveReferences: {maxDepth: 5 })
      _rawBenefits(resolveReferences: {maxDepth: 5 })
      _rawEvidence(resolveReferences: {maxDepth: 5 })
      _rawResources(resolveReferences: {maxDepth: 5 })
      versionNumber
      lastUpdatedDate(formatString: "YYYY-MM-DD", locale: "en-US")
      publicationStatus
      effectivePractices {
        _key
        title
        actionablePractices {
          _key
          title
          _rawImplementationStrategies(resolveReferences: {maxDepth: 10 })
        }
      }
      programmaticAssessments {
        _key
        title
        _rawBody(resolveReferences: {maxDepth: 10 })
      }
      relatedSections {
        heading
        seo {
          title
        }
        slug {
          current
        }
        publicationStatus
      }
    }

    allNonVersionedDocuments: allSanityEp3Document(filter: {publicationStatus: {ne: "live" } }) {
      nodes {
        slug {
          current
        }
        publicationStatus
      }
    }

    allNonVersionedPages: allSanityPage(filter: {publicationStatus: {ne: "live" } }) {
      nodes {
        slug {
          current
        }
        publicationStatus
      }
    }
    allSanityContributorsAndReviewers(filter: {ep3Document: {slug: {current: {eq: $slug } } } }) {
      edges {
        node {
          id
          _rawBody(resolveReferences: {maxDepth: 5 })
        }
      }
    }

    glossaryText: allSanityCustomTextBlock(filter: {target: {eq: "glossary-drawer" } }) {
      nodes {
      content: _rawContent(resolveReferences: {maxDepth: 10 })
      }
    }
  }`;
