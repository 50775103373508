import * as React from "react";
import { DynamicComponent } from "@components/DynamicComponent";
import { pageAtom } from "@/store";
import { useAtom } from "jotai";
import { Indicator } from "@components/Collapsible";
import { Heading } from "@components/Heading";
import { Button } from "@components/Button";
import * as styles from "../index.module.css";
import { useAssessmentsToggle } from "../store";
import { Collapsible } from "../Collapsible";
import { paramCase } from "change-case";

const ProgrammaticAssessmentsGroup = ({ practice: { title, _rawBody } }: any) => {
  return (
    <li className="grouping-assessment mt-4 list-decimal scroll-mt-10 lg:scroll-mt-20" id={paramCase(title)} data-targetable>
      <div className="[&>div>button]:bg-white [&>div>button]:sticky  [&>div>button]:top-[70px] lg:[&>div>button]:top-[74px] [&>div>button]:z-50">
        {/* bg-white sticky  top-[70px] lg:top-[74px] z-50"> */}
        <Collapsible title={title} target="assessment">
          <DynamicComponent blocks={_rawBody} />
        </Collapsible>
      </div>
    </li>
  );
};

export const ProgrammaticAssessments = () => {
  const [_page] = useAtom(pageAtom);
  const [show, updateToggle] = useAssessmentsToggle();
  const onClick = React.useCallback(() => updateToggle(!show), [updateToggle, show]);

  // @ts-ignore
  const { programmaticAssessments = [] } = _page?.sanityEp3Document || {};
  return programmaticAssessments.length > 0 ? (
    <div id="programmatic-assessments" className="assessment-content scroll-mt-10 lg:scroll-mt-20" data-targetable>
      <div className={styles.headerLine}>
        <Heading level={2} className="mb-0">
          Programmatic Assessments
        </Heading>

        <div className="flex mt-8 gap-8 max-w-fit">
          <Button onClick={onClick} variant="action">
            <Indicator open={show} />
            {show ? "Collapse" : "Expand"} all assessments
          </Button>
        </div>
      </div>
      <ol className="ep3doc-programmatic-assessment ml-6 [&_li::marker]:leading-8 [&_li::before]:leading-8">
        {programmaticAssessments.map((practice: any) => (
          <ProgrammaticAssessmentsGroup practice={practice} key={practice._key} />
        ))}
      </ol>
    </div>
  ) : null;
};
