import { Button } from "@components/Button";
import * as React from "react";
import { BiSearchAlt } from "react-icons/bi";
import { BsBook, BsCardChecklist, BsClipboardData, BsPencilSquare } from "react-icons/bs";
import { RiGroupLine } from "react-icons/ri";
export const menuItems = [
  {
    name: "contributors",
    Icon: RiGroupLine,
    label: "Contributors and Reviewers",
  },
  {
    name: "citation",
    label: "Cite this article",
  },
  {
    name: "programmatic-assessments",
    Icon: BsPencilSquare,
    label: "Programmatic Assessments",
  },
  {
    name: "resources",
    Icon: BsBook,
    label: "Resources",
  },
  {
    name: "evidence",
    label: "Evidence",
    Icon: BsClipboardData,
  },
  {
    name: "effective-practices",
    Icon: BsCardChecklist,
    label: "Effective Practices",
  },
  {
    name: "glossary",
    Icon: BiSearchAlt,
    label: "Glossary",
  },
] as const;

export const Icon = (props: { className?: string; name: MenuItem["name"] }) => {
  const DefIcon = menuItems.find((i) => i.name === props.name);
  // @ts-ignore
  return DefIcon.Icon ? <DefIcon.Icon className={props.className} /> : null;
};
type MenuItem = typeof menuItems[number];

export const MenuExpander = (props: { onClick: () => void; name: MenuItem["name"] }) => {
  const label = menuItems.find((i) => i.name === props.name)!.label;

  return (
    <Button
      variant="action"
      className="btn btn--action btn--faded  w-[max-content]  h-[max-content] flex items-center text-center"
      data-trigger={props.name}
      onClick={props.onClick}
    >
      <div className="h-4 w-4 flex items-center justify-center">
        <Icon name={props.name} />
      </div>
      <span>{label}</span>
    </Button>
  );
};
