import * as React from "react";
export const Indecator = ({ open }: { open: boolean }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {open ? (
        <path
          d="M19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21ZM5 5V19H19V5H5ZM17 13H7V11H17V13Z"
          fill="currentColor"
        ></path>
      ) : (
        <path
          d="M19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21ZM5 5V19H19V5H5ZM13 17H11V13H7V11H11V7H13V11H17V13H13V17Z"
          fill="currentColor"
        ></path>
      )}
    </svg>
  );
};
