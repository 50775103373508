import { definitionsAtom, pageAtom } from "@/store";
import { DynamicComponent } from "@components/DynamicComponent";
import { RenderMultiple } from "@components/RenderMultiple";
import { useAtom } from "jotai";
import { Disclosure, Transition } from "@headlessui/react";
import * as styles from "./index.module.css";
import * as React from "react";
import { titleCase } from "title-case";

const GlossaryDefinition = ({ definition }) => (
  <Disclosure>
    {({ open }) => (
      <>
        <Disclosure.Button className={"flex justify-between item-center mt-5"}>
          <span className="text-xl text-black font-base text-left">{titleCase(definition.term)}</span>

          <Transition
            show={!open}
            enter="transition duration-[.4s] ease-out"
            enterFrom="transform opacity-0"
            enterTo="transform opacity-100"
            leave="transition duration-[.2s]  ease-out"
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0"
          >
            <span className="border rounded-md border-primary text-primary px-2">Read</span>
          </Transition>
        </Disclosure.Button>
        <div className="overflow-hidden max-h-fit z-10">
          <Transition
            show={open}
            enter="transition duration-[.4s] ease-out"
            enterFrom="transform w-full max-h-[0px] opacity-0 -translate-y-[100%]"
            enterTo="transform max-h-full opacity-100 -translate-y-0"
            leave="transition duration-[.2s]  ease-out"
            leaveFrom="transform max-h-full opacity-100 -translate-y-0"
            leaveTo="transform opacity-0 -translate-y-[100%] "
          >
            <Disclosure.Panel>
              <div className="[&_p]:text-sm">
                <DynamicComponent blocks={definition.definition} />
              </div>
            </Disclosure.Panel>
          </Transition>
        </div>
      </>
    )}
  </Disclosure>
);

const GlossaryGroup = ({ letter, definitions }) => (
  <div className="flex flex-col">
    {/* <Heading level={3} className="border-b border-b-slate-400 pb-0 !text-xl">
      {letter}
    </Heading> */}
    <RenderMultiple fromPropsList={definitions.map((definition: any) => ({ definition }))} of={GlossaryDefinition} />
  </div>
);

export const Glossary = () => {
  const [definitions] = useAtom(definitionsAtom);

  const [page] = useAtom(pageAtom);

  return (
    <div className={styles.glossaryContent}>
      <DynamicComponent blocks={page.glossaryText.nodes[0].content} />
      <RenderMultiple
        of={GlossaryGroup}
        fromPropsList={Object.entries(definitions)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map(([letter, definitions]) => ({
            letter,
            definitions,
          }))}
      />
    </div>
  );
};
