import * as React from "react";
import { DynamicComponent } from "@components/DynamicComponent";
import { Collapsible } from "../Collapsible";
import { paramCase } from "change-case";


export const Practices: React.FC<{ title: string; show: boolean; blocks: any, offset: number; }> = (props) => {
  return (
    <li data-offset={props.offset} className={`ep3doc-practice scroll-mt-[var(--header-offset,0)] lg:scroll-mt-[var(--header-offset,0)]`} data-targetable id={paramCase(props.title)}>
      <Collapsible title={props.title} target="effectivePractice">
        <DynamicComponent blocks={props.blocks} />
      </Collapsible>
    </li>
  );
};
