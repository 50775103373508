import * as React from "react";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import { clsx } from "clsx";

const iconClasses = "w-4 h-4 stroke-2 text-primary";

export const ExpandButton = (props: { open?: boolean; title: string; }) => {
  return (
    <div className="gap-4 flex justify-between items-start w-full">
      <div className="whitespace-normal text-left font-semibold">{props.title}</div>
      <div className="mt-1">
        <BsChevronRight className={clsx(iconClasses, "group-[.preline-enabled]:hidden", !props.open && "hidden")} />
        <BsChevronDown className={clsx(iconClasses, "group-[.preline-enabled]:hidden", props.open && "hidden")} />
        <BsChevronRight className={clsx(iconClasses, "group-[.preline-enabled]:block hs-collapse-open:rotate-90")} />
      </div>
    </div >
  )
}
