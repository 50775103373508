/* eslint-disable functional/immutable-data */
import * as React from "react";

import "number-to-text/converters/en-us";
import { Indicator } from "@components/Collapsible";
import { RenderMultiple } from "@components/RenderMultiple";
import { EffectivePracticeGroup } from "./EffectivePracticeGroup";
import { Button } from "@components/Button";
import { Heading } from "@components/Heading";
import * as styles from "../index.module.css";
import { useEffectivePracticesToggle } from "../store";

export const EffectivePractices = ({ practices }: any) => {
  const [show, updateToggle] = useEffectivePracticesToggle();
  const onClick = React.useCallback(() => updateToggle(!show), [updateToggle, show]);
  return (
    <div id="effective-practices" className={styles.effectivePractices + " scroll-mt-10 lg:scroll-mt-20"} data-targetable>
      <div className={styles.headerLine}>
        <Heading level={2} className="mb-0">
          Effective Practices
        </Heading>

        <div className="flex mt-8 gap-8 max-h-8 max-w-fit">
          <Button onClick={onClick} variant="action">
            <Indicator open={show} />
            {show ? "Collapse" : "Expand"} all implementation strategies
          </Button>
        </div>
      </div>
      <ol className="ep3doc-effective-practices ml-6 [&>li::marker]:text-2xl" >
        <RenderMultiple of={EffectivePracticeGroup} fromPropsList={practices} />
      </ol>
    </div>
  );
};
