import { paramCase } from "change-case";
import { ExpandButton } from "../ExpanderButton";
import { useCollapse } from "../store";
import clsx from "clsx";
import * as React from "react";

export const sideBarStyles = `
  after:overflow-hidden after:overflow-hidden
  before:bg-primary before:w-[9px] before:h-[9px] before:rounded-full before:absolute before:[contents:''] before:ml-[-5px]
  after:bg-primary after:w-[9px] after:h-[9px] after:rounded-full after:absolute after:[contents:''] after:ml-[-5px]
  border-[#e5e7eb] border-l ml-8 w-2 h-auto after:bottom-0 relative mt-[.6rem]
        `;

type CollapsibleProps = {
  title: string;
  children?: React.ReactNode;
  target: "effectivePractice" | "assessment";
  className?: string;
};

export const Collapsible = ({ title, children, className = "", target, ...props }: CollapsibleProps) => {
  const id = React.useId();
  const targetId = paramCase(id);
  const buttonId = id + "-trigger";
  useCollapse(targetId, target);

  React.useEffect(() => {
    const triggerFn = (e) => {
      if (e.newUrl ? e.newUrl.includes(paramCase(title)) : window.location.hash.includes(paramCase(title))) {
        const el = document.getElementById(targetId);
        HSCollapse.show(el!);
      }
    };
    window.addEventListener("hashchange", triggerFn);
    return () => window.removeEventListener("hashchange", triggerFn);
  });

  return (
    <div className="flex flex-col group preline-enabled flex-grow gap-2">
      <button
        className="hs-collapse-toggle btn btn--action mt-2 bg-transparent hover:bg-[#ECECEC] py-3 gap-x-3 w-full font-semibold text-left"
        aria-controls={targetId}
        id={buttonId}
        data-hs-collapse={"#" + targetId}
      >
        <ExpandButton title={title} />
      </button>
      <div
        id={targetId}
        className={clsx("hs-collapse ml-[-2.5rem] hidden w-full overflow-hidden transition-[height] duration-300")}
        aria-labelledby={buttonId}
      >
        <div className="grid grid-cols-[auto_1fr]">
          <div className={sideBarStyles} />
          {children}
        </div>
      </div>
    </div>
  );
};
